import React, { useState, useContext } from "react";
import { graphql, Link } from "gatsby";

import "./film.scss";
import SEO from "../components/seo/seo";
import ViewportContext from "../components/utils/viewportContext";
import { getFilmImageGif } from "../components/utils/getters";

const VIMEO_HREF = "https://vimeo.com/tommygenes";

function generateRandomNumberBetween(max, min) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function calculateImagePosition() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const SIDE_PADDING_MODIFIER = 0.05;
    const TOP_PADDING_MODIFIER = 0.1;
    const IMAGE_WIDTH = 500;

    const maxLeft = windowWidth - (windowWidth * SIDE_PADDING_MODIFIER) - IMAGE_WIDTH;
    const minLeft = windowWidth * SIDE_PADDING_MODIFIER;

    const maxTop = windowHeight - (windowHeight * TOP_PADDING_MODIFIER) - (IMAGE_WIDTH * 0.4);
    const minTop = windowHeight * TOP_PADDING_MODIFIER;

    const randomX = generateRandomNumberBetween(maxLeft, minLeft);
    const randomY = generateRandomNumberBetween(maxTop, minTop);

    return {
        top: Math.floor(randomY),
        left: Math.floor(randomX),
        width: `${IMAGE_WIDTH}px`
    }
}

const FilmIndexPage = ({ data }) => {
    const films = data.allFilm.edges.map(filmNode => filmNode.node);
    const viewportType = useContext(ViewportContext);
    const [hoveredFilm, setHoveredFilm] = useState(null);

    let hoveredFilmContainer = null;
    if (hoveredFilm && !!hoveredFilm.image && viewportType === 'largeScreen') {
        const filmImage = hoveredFilm.image.ext === '.gif' && getFilmImageGif(hoveredFilm);

        const imagePositionStyle = calculateImagePosition();

        hoveredFilmContainer = (
            <div className={"film-preview"} style={imagePositionStyle} key={`film-${hoveredFilm.slug}-image`}>
                <img className={"film-preview__img"} src={filmImage}/>
            </div>
        );
    }

    return (
        <>
            <SEO title={"Films - TOMMY GENES"} />

            <div className={"film-index-list"}>
                {films.map((film, i) => (
                    <Link to={film.path} key={`film-${i}`}
                          onMouseEnter={() => setHoveredFilm(film)}
                          onMouseLeave={() => setHoveredFilm(null)}
                    >
                        <div className={"film-list-item"}>
                            <h3 className={"film-list-item__title font-secondary"}>{film.title}</h3>
                            <span className={"film-list-item__length"}>{film.film_length}</span>
                        </div>
                    </Link>
                ))}
            </div>

            {hoveredFilmContainer}

            <div className={"info-footer font-primary"}>
                <div className={"info-footer__content"}>
                    <a href={VIMEO_HREF} target="_blank">Vimeo</a>
                </div>
            </div>
        </>
    );
};

export default FilmIndexPage;

export const query = graphql`
query FilmIndexQuery {
  allFilm {
    edges{
      node {
        slug
        title
        film_length
        path
        video_short {
          url
        }
        image {
          ext
          url
        }
      }
    }
  }
}
 `;
